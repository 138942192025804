<div class="customizer border-left-blue-grey border-left-lighten-4 d-none d-xl-block" id="customizer">
  <a [routerLink]="" class="customizer-close" (click)="toggleCustomizer($event)"><i class="feather ft-x font-medium-3"></i></a>
  <a [routerLink]="" class="customizer-toggle bg-danger box-shadow-3" (click)="toggleCustomizer($event)"><i
      class="feather ft-activity font-medium-3 white"></i></a>
  <div class="customizer-content p-2" fxFlex="auto" [perfectScrollbar]="config">
    <img />
    <h4 class="text-uppercase mb-0">KI BOT</h4>
    <hr>

    <!-- app.component.html -->
<!-- app.component.html -->
<div class="container py-4">
  <div class="row">
    <div class="col-12">
      <div #chatContainer class="chat-container border rounded p-3" style="height: 500px; overflow-y: scroll;">
        <!-- Schleife durch jeden Eintrag im Chatverlauf -->
        <div *ngFor="let message of messages" class="mb-0.5">
          <div class="d-flex" [ngClass]="{'justify-content-end': message.sender === 'user', 'justify-content-start': message.sender === 'ai'}">
            <!-- Zeige die Nachricht an -->
            <div class="message p-1 rounded" [ngClass]="{'bg-red-600 text-white': message.sender === 'user', 'bg-light': message.sender === 'ai'}">
              {{ message.text }}
            </div>
          </div>
        </div>
      </div>
      
      <!-- Eingabefeld und Button, um neue Nachrichten zu senden -->
      <div class="input-area mt-3">
        <div class="input-group">
          <input type="text" [(ngModel)]="userInput" class="form-control" placeholder="Schreibe eine Nachricht...">
          <button (click)="sendPrompt()" class="btn btn-primary">Senden</button>
          <button (click)="getCompletion('Hello, who are you?')" class="btn btn-primary">Create</button>
          <a class="btn btn-success" (click)="listen()">listen</a>
          <a class="btn btn-success" (click)="stop()">stop</a>
          <a (click)="speak()">speak</a>
          {{text}}
        </div>
      </div>
    </div>
  </div>
</div>


    
   
  </div>
</div>
